'use client'
import { ThemeSwitch } from "@/components/theme-switch";
import { User } from "@/config/interfaces";
import { siteConfig } from "@/config/site";
import {
    Avatar,
    Badge,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    NavbarMenu,
    NavbarMenuItem,
    NavbarMenuToggle,
    Navbar as NextNavbar
} from "@nextui-org/react";
import { link as linkStyles } from "@nextui-org/theme";
import { Role } from "@prisma/client";
import { IconBriefcase, IconCash, IconSettings, IconUser } from "@tabler/icons-react";
import clsx from "clsx";
import Link from 'next/link';
import React from "react";

export default function Navbar({ user }: { user: User }) {
    const isAdmin = user && user.role === Role.ADMIN;
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const ManagementDropdown = ({ onPress }: { onPress?: () => void }) => (
        <Dropdown>
            <NavbarItem>
                <DropdownTrigger>
                    <Button
                        isIconOnly={onPress === undefined}
                        disableRipple
                        className={clsx(
                            linkStyles({ color: "foreground" }),
                            "p-0 font-bold bg-transparent data-[hover=true]:bg-transparent data-[active=true]:text-primary data-[active=true]:font-medium"
                        )}
                        startContent={<IconSettings />}
                        radius="sm"
                    >
                        {onPress ? "Gestione" : ""}
                    </Button>
                </DropdownTrigger>
            </NavbarItem>
            <DropdownMenu
                aria-label="Gestione"
                itemClasses={{
                    base: "gap-4",
                }}
            >
                <DropdownItem key="users" as={Link} href="/users" onPress={onPress}>
                    <div className="flex flex-row items-center gap-2">
                        <IconUser />
                        Utenti
                    </div>
                </DropdownItem>
                <DropdownItem key="payments" as={Link} href="/payments" onPress={onPress}>
                    <div className="flex flex-row items-center gap-2">
                        <IconCash />
                        Pagamenti
                    </div>
                </DropdownItem>
                <DropdownItem key="projects" as={Link} href="/projects" onPress={onPress}>
                    <div className="flex flex-row items-center gap-2">
                        <IconBriefcase />
                        Progetti
                    </div>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );

    const profile = (
        <Link href={'/dashboard'}>
            <div className="flex items-center justify-center pt-2 sm:justify-end">
                <div className="flex gap-4">
                    <div className="flex flex-col gap-1 items-center sm:items-end justify-center">
                        <h4 className="text-small font-semibold leading-none text-default-600 line-clamp-1">{user?.firstName} {user?.lastName}</h4>
                        <h5 className="text-small tracking-tight text-default-400">{user?.cardCode}</h5>
                    </div>
                    <Badge color="success" content="">
                        <Avatar isBordered radius="full" size="sm" src="/blank.png" />
                    </Badge>
                </div>
            </div>
        </Link>
    );

    return (
        <NextNavbar maxWidth="2xl" height="6rem" isMenuOpen={isMenuOpen} shouldHideOnScroll={false} className="fixed top-0 left-0 right-0 z-50"
            onMenuOpenChange={setIsMenuOpen} isBlurred={false} isBordered={true}>
            <NavbarContent className="basis-1/5 sm:basis-full" justify="start">
                <NavbarBrand className="gap-3 max-w-fit">
                    <Link className="flex justify-start items-center gap-1" href="/">
                        <p className="font-bold text-inherit">Štivor</p>
                    </Link>
                </NavbarBrand>
                <ul className="hidden sm:flex gap-4 justify-start items-center ml-2">
                    {siteConfig.navItems.map((item) => (
                        (!item.loggedIn || (item.loggedIn && user)) && (
                            <NavbarItem key={item.href}>
                                <Link
                                    className={clsx(
                                        linkStyles({ color: "foreground" }),
                                        item.label === "Archivio" ? "text-warning-400" : "",
                                        "data-[active=true]:text-primary data-[active=true]:font-medium"
                                    )}
                                    color="foreground"
                                    href={item.href}
                                >
                                    {item.label}
                                </Link>
                            </NavbarItem>)
                    ))}
                </ul>
            </NavbarContent>

            <NavbarContent
                className="hidden sm:flex basis-1/5 sm:basis-full"
                justify="end"
            >
                {isAdmin && (
                    <ManagementDropdown />
                )}

                {user && (
                    <NavbarItem className="hidden lg:flex">{profile}</NavbarItem>
                )}
                <NavbarItem className="hidden sm:flex gap-2">
                    <ThemeSwitch />
                </NavbarItem>
            </NavbarContent>

            <NavbarContent className="sm:hidden basis-1 pl-4" justify="end">
                <ThemeSwitch />
                <NavbarMenuToggle />
            </NavbarContent>

            <NavbarMenu>
                {user && (<div onClick={() => {
                    setIsMenuOpen(!isMenuOpen)
                }}>
                    {profile}
                </div>
                )}
                <div className="mx-4 mt-2 flex flex-col gap-2 text-center">
                    {siteConfig.navItems.map((item, index) => (
                        (!item.loggedIn || (item.loggedIn && user)) && (
                            <NavbarMenuItem key={`${item}-${index}`}>
                                <Link
                                    color={index === siteConfig.navItems.length - 1
                                        ? "primary"
                                        : "foreground"
                                    }
                                    href={item.href}
                                    onClick={() => {
                                        setIsMenuOpen(!isMenuOpen)
                                    }}
                                >
                                    {item.label}
                                </Link>
                            </NavbarMenuItem>
                        )))}
                    {isAdmin && (
                        <div className="flex py-4 justify-center">
                            <ManagementDropdown onPress={() => {
                                setIsMenuOpen(!isMenuOpen)
                            }} />
                        </div>
                    )}
                </div>
            </NavbarMenu>
        </NextNavbar>
    );
};
