"use client";

import { NextUIProvider } from "@nextui-org/react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ThemeProvider as NextThemesProvider, ThemeProviderProps } from "next-themes";
import { useRouter } from "next/navigation";
import * as React from "react";
import { Toaster } from "react-hot-toast";

export interface ProvidersProps {
    children: React.ReactNode;
    themeProps?: ThemeProviderProps;
}

const payPalOptions = {
    clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID as string,
    currency: "EUR",
    intent: "capture",
}

export function Providers({ children, themeProps }: ProvidersProps) {
    const router = useRouter();
    return (
        <PayPalScriptProvider options={payPalOptions}>
            <NextUIProvider navigate={router.push}>
                <NextThemesProvider {...themeProps}>{children}</NextThemesProvider>
                <Toaster />
            </NextUIProvider>
        </PayPalScriptProvider>
    );
}
