'use client'
import { AnimatePresence, motion } from "motion/react";
import { useEffect, useState } from "react";

export default function RotateText({ words, className }: { words: string[], className?: string }) {
    const [index, setIndex] = useState(0);
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        const initialTimeout = setTimeout(() => {
            setShowAnimation(true);
        }, 0);

        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 4000);

        return () => {
            clearTimeout(initialTimeout);
            clearInterval(interval);
        };
    }, [words.length]);

    return (
        <AnimatePresence mode="wait">
            <motion.h1
                key={words[index]}
                initial={{ opacity: showAnimation ? 0 : 1, y: showAnimation ? -50 : 0 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
                className={className}
            >
                {words[index]}
            </motion.h1>
        </AnimatePresence>
    );
}